<template>
	<div>
	<InPageImage></InPageImage>
	<div class="wrapper">
		
		<div class="bg-white baseContent" style="box-sizing: border-box">
			<Breadcrumb :crumbsList="pathList"></Breadcrumb>
			<div class="" style="padding:20px 0;">
				<div class="gsImage">
					<el-image :src="obj.image" fit="cover"></el-image>
				</div>
				<div class="margin-top-20">
					<div class="base-font-28 text-bold">{{ obj.title }}</div>
					<div v-html="obj.content" class="base-font-24 text-666 margin-top-10 text-left" style="line-height:2.1;">
					</div>
				</div>
			</div>
			<div class="" style="padding:50px 0;border-top:1px solid #eeeeee">
				<el-row :gutter="20">
					<el-col :span="8">
						<div class="lxwmBox text-center">
							<div class=""><i class="el-icon-phone-outline base-font-40 text-blue"></i></div>
							<div class="base-font-18 text-666 margin-top-10 text-bold">联系电话</div>
							<div class="base-font-18 text-666 margin-top-10">{{ sysConfigList['web_linker_phone'] }}</div>
							
						</div>
					</el-col>
					<el-col :span="8">
						<div class="lxwmBox text-center">
							<div class=""><i class="el-icon-location-information base-font-40 text-blue"></i></div>
							<div class="base-font-18 text-666 margin-top-10 text-bold">地址</div>
							<div class="base-font-18  text-666 margin-top-10">
                {{ sysConfigList['web_linker_address'] }}
							</div>
						</div>
					</el-col>
					<el-col :span="8">
						<div class="lxwmBox text-center">
							<div class=""><i class="el-icon-message base-font-40 text-blue"></i></div>
							<div class="base-font-18 text-666 margin-top-10 text-bold">邮箱</div>
							<div class="base-font-18  text-666 margin-top-10">
                {{ sysConfigList['web_email'] }}
							</div>
						</div>
					</el-col>
				</el-row>
			</div>
		</div>
	</div>
	</div>
</template>

<script>
	import Breadcrumb from '../../components/breadcrumbs.vue'
	import InPageImage from '../../components/inPageImage.vue'
  import {getOneWzContent, getSysConfigByFlage, menuByUrl} from "@/api/api";
	export default {
		components: {
			Breadcrumb,InPageImage
		},
		data() {
			return {
        obj:{},
        leftMenu:{childList:[]},
        pathList:[],
        sysConfigList:[]
			}
		},
    mounted() {
      var kindId=this.$route.query.kindId;
      var path=this.$route.path+"?kindId="+kindId;
      console.log("path:"+path);
      this.getContent(kindId);
      this.menuByUrl(path);
      this.getSysConfigByFlage();
    },
		methods: {
      getContent(kindId) {
        getOneWzContent(kindId).then(res => {
          this.obj=res.data;
        })
      },
      getSysConfigByFlage() {
        getSysConfigByFlage(5).then(res => {
          this.sysConfigList=res.data;
        })
      },
      menuByUrl(path) {
        menuByUrl(1,path).then(res => {
          this.pathList=res.data.pathList;
          this.leftMenu=res.data.leftMenu;
        })
      },
			handleClick(id) {
				this.$router.push({
					path: '/shzp?id=' + id
				});
			},
			goZp() {
				this.$router.push({
					path: '/shzp'
				});
			}
		}
	}
</script>

<style>
	.lxwmBox {
		margin: 0 auto;
	}

.gsImage{width:690px;height:460px;}
.gsImage .el-image{width:690px;height:460px;}

</style>
